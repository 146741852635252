<template>
  <div>
    <blank-view />
  </div>
</template>

<script>
export default {
  name: 'LayoutsBlank',
  components: {
    // BlankFooter: () => import("./Footer"),
    BlankView: () => import('./View.vue')
  },
};
</script>
